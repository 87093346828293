import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import useStore from 'src/store/cns';
import { register } from 'src/utils/contract';
import { Token } from 'src/types/Common';
import Loading from '../widget/Loading';
import TokenSelection from '../widget/TokenSelection';
import TimePeriod from '../widget/TimePeriod';

const RegisterDomain = () => {
  const isLoggedIn = useStore(state => state.isLoggedIn);
  const registerState = useStore(state => state.registerState);
  const setRegisterState = useStore(state => state.setRegisterState);

  const getOneYearFromNow = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() + 1);
    return today.toISOString().split('T')[0];
  };

  const selectedToken = useStore(state => state.selectedToken);

  const [expirationDate, setExpirationDate] = useState(getOneYearFromNow());
  const [registrationPeriod, setRegistrationPeriod] = useState(1);
  const [registrationCost, setRegistrationCost] = useState(
    Number(selectedToken?.basePrice),
  );
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (token: Token) => {
    if (token?.basePrice) {
      setRegistrationCost(token?.basePrice * registrationPeriod);
    }
  };

  const registerDomain = async () => {
    if (selectedToken && isLoggedIn) {
      try {
        setLoading(true);
        const registrationStatus = await register(
          registerState.domainName,
          registrationPeriod,
          registrationCost.toString(),
        );
        if (registrationStatus) {
          setRegisterState({
            domainName: registerState.domainName,
            available: true,
            status: 'success',
          });
        } else {
          setRegisterState({
            domainName: registerState.domainName,
            available: true,
            status: 'error',
          });
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setRegisterState({
          domainName: registerState.domainName,
          available: true,
          status: 'error',
        });
      }
    }
  };

  const handleIncrementYear = () => {
    setExpirationDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setFullYear(newDate.getFullYear() + 1);
      setRegistrationPeriod(prevPeriod => prevPeriod + 1);
      if (selectedToken?.basePrice) {
        setRegistrationCost(prevCost => prevCost + selectedToken?.basePrice);
      }
      return newDate.toISOString().split('T')[0];
    });
  };

  const handleDecrementYear = () => {
    setExpirationDate(prevDate => {
      const newDate = new Date(prevDate);
      const today = new Date();
      today.setFullYear(today.getFullYear() + 1);
      if (newDate.getFullYear() > today.getFullYear()) {
        newDate.setFullYear(newDate.getFullYear() - 1);
        setRegistrationPeriod(prevPeriod => prevPeriod - 1);
        if (selectedToken?.basePrice) {
          setRegistrationCost(prevCost => prevCost - selectedToken?.basePrice);
        }
      }
      return newDate.toISOString().split('T')[0];
    });
  };

  return (
    <Container
      data-cy="register-domain"
      maxWidth="sm"
      sx={{
        mt: 20,
        border: '1px solid',
        borderColor: 'grey.600',
        borderRadius: '8px',
        paddingX: '50px',
        paddingY: '24px',
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
        You are about to register: {registerState.domainName}.cam
      </Typography>
      <Grid container rowSpacing={3}>
        <Grid item xs={8} display="flex" alignItems="center">
          <Typography>Expiration Date</Typography>
          <TimePeriod
            timePeriod={registrationPeriod}
            handleIncrementYear={handleIncrementYear}
            handleDecrementYear={handleDecrementYear}
          />
        </Grid>
        <Grid item xs={4}>
          <Box>
            <TextField
              fullWidth
              variant="outlined"
              value={expirationDate}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarTodayIcon style={{ color: '#9c9c9c' }} />
                  </InputAdornment>
                ),
                style: { color: '#fff' },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '46px',
                  '&:hover fieldset': {
                    borderColor: 'grey.600',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'grey.600',
                  },
                },
              }}
              disabled
            />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Typography>Registration Token</Typography>
        </Grid>
        <Grid item xs={4} textAlign="right">
          <TokenSelection onValueChange={handleChange} />
        </Grid>
        <Grid item xs={8}>
          <Typography>Registration Cost</Typography>
        </Grid>
        <Grid item xs={4} textAlign="right" data-cy="registration-base-price">
          <Typography>
            {registrationCost} {selectedToken?.symbol}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 2, color: 'divider' }} />
        </Grid>
        <Grid item xs={8}>
          <Typography>Total</Typography>
        </Grid>
        <Grid item xs={4} textAlign="right">
          <Typography>
            {registrationCost} {selectedToken?.symbol}
          </Typography>
        </Grid>
        {loading ? (
          <Grid
            item
            xs={12}
            textAlign="center"
            sx={{ mt: 2 }}
            data-cy="loading-spinner"
          >
            <Loading text="Registering domain..." />
          </Grid>
        ) : (
          <Grid item xs={12} textAlign="center" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              onClick={() => registerDomain()}
              sx={{
                color: 'text.primary',
                backgroundColor: 'primary.main',
                padding: '10px 16px',
              }}
            >
              Register
            </Button>
          </Grid>
        )}
        {loading ? null : (
          <Grid item xs={12} textAlign="center" sx={{ mt: 2 }}>
            <Button
              sx={{
                color: 'text.primary',
                borderColor: 'grey.600',
                '&:hover': {
                  backgroundColor: 'grey.800',
                  borderColor: 'grey.600',
                },
              }}
              variant="outlined"
              onClick={() =>
                setRegisterState({
                  domainName: '',
                  available: false,
                  status: 'searching',
                })
              }
            >
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default React.memo(RegisterDomain);
