import { ethers } from 'ethers';
import { getState } from '../store/cns';

export const getProvider = (): ethers.Provider => {
  return new ethers.JsonRpcProvider(
    'https://columbus.camino.network/ext/bc/C/rpc',
  );
};

export const getSigner = (): ethers.Signer | null => {
  const wallet = getState().wallet;
  const provider = getProvider();
  return wallet ? wallet.connect(provider) : null;
};
