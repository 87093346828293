export enum Chain {
  X,
  P,
  C,
}

export interface RegisterState {
  domainName: string;
  available: boolean;
  status: 'success' | 'error' | 'registering' | 'searching' | 'open';
}

export interface Token {
  tokenAddress: string;
  basePrice: number;
  name: string;
  symbol: string;
}

export interface Domain {
  name: string;
  accessRights: string;
  subdomains: Domain[];
  resolver?: ResolverData;
  managers?: string[];
}

export type ContractCall = 'idle' | 'loading' | 'success' | 'error';

interface ResolverData {
  resolverAddress: string;
  cChainAddress: string;
  xChainAddress: string;
  pChainAddress: string;
  publicKey: string;
  contentHash: string;
}

export type DomainTree = {
  name: string;
  owner: string;
  hash: string;
  subdomains: DomainTree[];
};
