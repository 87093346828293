import { create, StoreApi } from 'zustand';
import { ethers } from 'ethers';
import { Token, Domain, RegisterState, DomainTree } from 'src/types/Common';

type Status = 'idle' | 'loading' | 'success' | 'error';
type SnackBar = {
  open: boolean;
  message: string;
  severity: 'success' | 'error';
};

interface StoreState {
  wallet: ethers.Wallet | undefined;
  isLoggedIn: boolean;
  status: Status;
  registeredDomains: Array<{ name: string; owner: string }>;
  domains: Array<Domain>;
  domainTree: DomainTree | undefined;
  selectedDomain: Domain | undefined;
  tokens: Array<Token>;
  selectedToken: Token | undefined;
  operators: string[];
  snackbar: SnackBar;
  callState: Status;
  loadingText: string;
  fetchingNetworkData: boolean;
  fetchingNetworkDataText: string;
  registerState: RegisterState;
  mode: 'light' | 'dark';
  toggleTheme: () => void;
  login: (wallet: ethers.Wallet) => void;
  logout: () => void;
  setStatus: (status: Status) => void;
  setRegisteredDomains: (
    domains: Array<{ name: string; owner: string }>,
  ) => void;
  setDomains: (domains: Domain[]) => void;
  setDomainTree: (tree: DomainTree | undefined) => void;
  setSelectedDomain: (domain: Domain | undefined) => void;
  clearDomains: () => void;
  setTokens: (tokens: Token[]) => void;
  setSelectedToken: (token: Token | undefined) => void;
  setOperators: (operators: string[]) => void;
  setSnackbar: (snackBar: SnackBar) => void;
  setCallState: (state: Status) => void;
  setLoadingText: (text: string) => void;
  setFetchingNetworkData: (loading: boolean) => void;
  setFetchingNetworkDataText: (text: string) => void;
  setRegisterState: (state: RegisterState) => void;
}

const useStore = create<StoreState>(set => ({
  wallet: undefined,
  isLoggedIn: false,
  status: 'idle',
  registeredDomains: [],
  domains: [],
  domainTree: undefined,
  tokens: [],
  selectedToken: undefined,
  selectedDomain: undefined,
  operators: [],
  snackbar: {
    open: false,
    message: '',
    severity: 'success',
  },
  callState: 'idle',
  loadingText: '',
  fetchingNetworkData: false,
  fetchingNetworkDataText: '',
  registerState: {
    domainName: '',
    available: false,
    status: 'searching',
  },
  mode: 'dark',
  toggleTheme: () =>
    set(state => ({
      mode: state.mode === 'dark' ? 'light' : 'dark',
    })),
  login: (wallet: ethers.Wallet) =>
    set(() => ({
      wallet: wallet,
      isLoggedIn: true,
    })),
  logout: () =>
    set(() => ({
      wallet: undefined,
      isLoggedIn: false,
    })),
  setRegisteredDomains: (domains: Array<{ name: string; owner: string }>) =>
    set(() => ({
      registeredDomains: domains,
    })),
  setDomains: (domains: Domain[]) =>
    set(() => ({
      domains: domains,
    })),
  setDomainTree: (tree: DomainTree | undefined) =>
    set(() => ({
      domainTree: tree,
    })),
  setStatus: (status: Status) =>
    set(() => ({
      status: status,
    })),
  clearDomains: () =>
    set(() => ({
      domains: [],
    })),
  setTokens: (tokens: Token[]) =>
    set(() => ({
      selectedToken: tokens[0],
      tokens: tokens,
    })),
  setSelectedToken: (token: Token | undefined) =>
    set(() => ({
      selectedToken: token,
    })),
  setSelectedDomain: (domain: Domain | undefined) =>
    set(() => ({
      selectedDomain: domain,
    })),
  setOperators: (operators: string[]) =>
    set(() => ({
      operators: operators,
    })),
  setSnackbar: (snackbar: SnackBar) =>
    set(() => ({
      snackbar: snackbar,
    })),
  setCallState: (state: Status) =>
    set(() => ({
      callState: state,
    })),
  setLoadingText: (text: string) =>
    set(() => ({
      loadingText: text,
    })),
  setFetchingNetworkData: (loading: boolean) =>
    set(() => ({
      fetchingNetworkData: loading,
    })),
  setFetchingNetworkDataText: (text: string) =>
    set(() => ({
      fetchingNetworkDataText: text,
    })),
  setRegisterState: (state: RegisterState) =>
    set(() => ({
      registerState: state,
    })),
}));

// Extend the global Window interface to include __store__
declare global {
  interface Window {
    __store__?: StoreApi<StoreState>;
  }
}

// Expose the Zustand store to Cypress for testing
if (typeof window !== 'undefined' && window.Cypress) {
  window.__store__ = useStore;
}

export const { getState, setState, subscribe } = useStore;

export default useStore;
