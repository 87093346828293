import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  FormControl,
  FormLabel,
} from '@mui/material';
import { setOwner, setTTL } from 'src/utils/contract';
import Loading from '../widget/Loading';
import useStore from 'src/store/cns';
import { useNavigate } from 'react-router-dom';

interface Props {
  owner: string;
  ttl: string;
  domain: string;
}

const DomainSettings: React.FC<Props> = ({ owner, ttl, domain }) => {
  const callState = useStore(state => state.callState);
  const setCallState = useStore(state => state.setCallState);
  const loadingText = useStore(state => state.loadingText);
  const setLoadingText = useStore(state => state.setLoadingText);
  const setSelectedDomain = useStore(state => state.setSelectedDomain);

  const [ttlInput, setTtlInput] = useState<string>('');
  const [ownerInput, setOwnerInput] = useState<string>('');

  const setSnackbar = useStore(state => state.setSnackbar);
  const navigate = useNavigate();

  const handleTTLUpdate = async () => {
    setLoadingText('Updating TTL...');
    setCallState('loading');
    if (await setTTL(domain.split('.'), ttlInput)) {
      setSnackbar({
        open: true,
        message: `Successfully updated TTL.`,
        severity: 'success',
      });
    } else {
      setSnackbar({
        open: true,
        message: `Failed to update TTL.`,
        severity: 'error',
      });
    }
    setCallState('idle');
  };

  const handleOwnerUpdate = async () => {
    setLoadingText('Setting new owner...');
    setCallState('loading');
    if (await setOwner(domain.split('.'), ownerInput)) {
      setSnackbar({
        open: true,
        message: `Successfully updated owner.`,
        severity: 'success',
      });
      setSelectedDomain(undefined);
      navigate('/cns');
    } else {
      setSnackbar({
        open: true,
        message: `Failed to update owner.`,
        severity: 'error',
      });
    }
    setCallState('idle');
  };

  const inputs = [
    {
      label: 'TTL (in ms)',
      prop: ttl,
      inputValue: ttlInput,
      function: setTtlInput,
      onClick: handleTTLUpdate,
    },
    {
      label: 'Owner',
      prop: owner,
      inputValue: ownerInput,
      function: setOwnerInput,
      onClick: handleOwnerUpdate,
    },
  ];

  useEffect(() => {
    setOwnerInput(owner);
    setTtlInput(ttl);
  }, [owner, ttl]);

  return (
    <Container maxWidth="sm">
      {callState === 'idle' ? (
        <Box>
          {inputs.map(input => (
            <Grid
              key={input.label}
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              direction="row"
              mb={5}
            >
              <Grid item xs={8}>
                <FormControl fullWidth>
                  <FormLabel
                    sx={{
                      position: 'absolute',
                      transform: 'translateY(-24px)',
                      pointerEvents: 'none',
                    }}
                  >
                    {input.label}
                  </FormLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={input.inputValue}
                    placeholder={`Set ${input.label}`}
                    onChange={e => input.function(e.target.value)}
                    InputLabelProps={{
                      shrink: false,
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        height: '48px',
                        '&:hover fieldset': {
                          borderColor: 'grey.600',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'grey.600',
                        },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  fullWidth
                  onClick={input.onClick}
                  sx={{
                    color: 'text.primary',
                    backgroundColor: 'primary.main',
                    padding: '10px 16px',
                  }}
                >
                  {input.prop.length > 0 ? (
                    <Typography>Update</Typography>
                  ) : (
                    <Typography>Set</Typography>
                  )}
                </Button>
              </Grid>
            </Grid>
          ))}
        </Box>
      ) : (
        <Loading text={loadingText} />
      )}
    </Container>
  );
};

export default DomainSettings;
