import React, { useEffect } from 'react';
import useStore from 'src/store/cns';
import SearchDomain from 'src/components/domain/SearchDomain';
import RegisterDomain from 'src/components/domain/RegisterDomain';
import { Box, Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getDomainTree } from 'src/utils/logs';
import DomainInformation from 'src/components/domain/DomainInformation';

const AuthStatus: React.FC = () => {
  const isLoggedIn = useStore(state => state.isLoggedIn);
  const registerState = useStore(state => state.registerState);
  const setRegisterState = useStore(state => state.setRegisterState);
  const setFetchingNetworkData = useStore(
    state => state.setFetchingNetworkData,
  );
  const setFetchingNetworkDataText = useStore(
    state => state.setFetchingNetworkDataText,
  );
  const setDomainTree = useStore(state => state.setDomainTree);
  const domainTree = useStore(state => state.domainTree);

  const navigate = useNavigate();

  const showDomain = () => {
    setRegisterState({
      domainName: '',
      available: false,
      status: 'searching',
    });
    navigate('/cns/my-domains');
  };

  const reset = () => {
    setRegisterState({
      domainName: '',
      available: false,
      status: 'searching',
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setFetchingNetworkDataText('Looking up domains. Please wait...');
      setFetchingNetworkData(true);
      try {
        const tree = await getDomainTree();
        setDomainTree(tree);
      } catch (error) {
        console.error(`Failed to query logs: ${error}`);
        return null;
      }
      setFetchingNetworkData(false);
      setFetchingNetworkDataText('');
    };

    if (!domainTree) {
      fetchData();
    }
  }, []);

  return (
    <Box
      data-cy="domain-search"
      sx={{
        background: theme => theme.palette.background.paper,
      }}
    >
      {registerState.status === 'searching' ? <SearchDomain /> : null}
      {registerState.status === 'open' ? <DomainInformation /> : null}
      {isLoggedIn &&
      registerState.domainName.length > 0 &&
      registerState.available &&
      registerState.status === 'registering' ? (
        <RegisterDomain />
      ) : null}
      {registerState.status !== 'searching' &&
      registerState.status !== 'registering' &&
      registerState.status !== 'open' ? (
        <Container maxWidth="lg" sx={{ mt: 24 }}>
          {registerState.status === 'success' ? (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h3" align="center" gutterBottom>
                Congratulations
              </Typography>
              <Typography variant="h6" align="center" sx={{ mb: 4 }}>
                You have successfully registered {registerState.domainName}.cam!
              </Typography>
              <Button
                variant="contained"
                onClick={() => showDomain()}
                sx={{
                  color: 'text.primary',
                  backgroundColor: 'primary.main',
                  padding: '10px 16px',
                }}
              >
                View Domain
              </Button>
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="h3"
                align="center"
                gutterBottom
                fontWeight="bold"
              >
                Something went wrong
              </Typography>
              <Typography variant="h6" align="center" sx={{ mb: 4 }}>
                We could not register {registerState.domainName}.cam for you
              </Typography>
              <Button
                variant="contained"
                onClick={() => reset()}
                sx={{
                  color: 'text.primary',
                  backgroundColor: 'primary.main',
                  padding: '10px 16px',
                }}
              >
                Try Again
              </Button>
            </Box>
          )}
        </Container>
      ) : null}
    </Box>
  );
};

export default AuthStatus;
