import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import useStore from 'src/store/cns';
import SearchBar from '../widget/SearchBar';
import { available } from 'src/utils/contract';
import { getLevel1Subdomains, validateInput } from 'src/utils/common';
import { useNavigate } from 'react-router-dom';

const SearchDomain = () => {
  const isLoggedIn = useStore(state => state.isLoggedIn);
  const setRegisterState = useStore(state => state.setRegisterState);
  const domainTree = useStore(state => state.domainTree);
  const setRegisteredDomains = useStore(state => state.setRegisteredDomains);
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [callState, setCallState] = useState<
    'idle' | 'available' | 'taken' | 'illegal_name'
  >('idle');

  const handleSearch = (query: string) => {
    setCallState('idle');
    setSearchQuery(query);
  };

  function isValidLabel(label: string): boolean {
    const regex = /^[a-z0-9]([a-z0-9-]{2,61}[a-z0-9])?$/;

    if (label.length < 4 || label.length > 63) {
      return false;
    }

    if (label.includes('.') || !regex.test(label)) {
      return false;
    }

    return true;
  }

  const handleEnterPress = async (query: string) => {
    if (isValidLabel(query)) {
      if (await available(query)) {
        setCallState('available');
        setRegisterState({
          domainName: query,
          available: true,
          status: 'searching',
        });
      } else {
        setCallState('taken');
        setRegisterState({
          domainName: query,
          available: false,
          status: 'searching',
        });
      }
    } else {
      setCallState('illegal_name');
    }
  };

  const openDomain = (domain: string) => {
    setRegisterState({
      domainName: domain,
      available: false,
      status: 'open',
    });
  };

  const handleClick = () => {
    setRegisterState({
      domainName: searchQuery,
      available: true,
      status: 'registering',
    });
  };

  const renderButtons = () => {
    if (isLoggedIn && searchQuery.length !== 0 && callState === 'available') {
      return (
        <Button
          variant="contained"
          onClick={() => handleClick()}
          sx={{
            color: 'text.primary',
            backgroundColor: 'primary.main',
            padding: '10px 16px',
          }}
        >
          Register
        </Button>
      );
    } else if (searchQuery.length !== 0) {
      return (
        <Button
          variant="contained"
          onClick={() => navigate('/wallet')}
          sx={{
            color: 'text.primary',
            backgroundColor: 'primary.main',
            padding: '10px 16px',
          }}
        >
          Connect Wallet
        </Button>
      );
    }
  };

  useEffect(() => {
    if (domainTree) {
      setRegisteredDomains(getLevel1Subdomains(domainTree));
    }
  });

  return (
    <Container maxWidth="lg" sx={{ mt: 20 }}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography
          data-cy="sd-title"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Search Your Camino Domain
        </Typography>
        <Typography
          data-cy="sd-subtitle"
          variant="h4"
          align="center"
          color="text.primary"
          sx={{ mb: 2 }}
        >
          Linking Identities to Wallets: Unifying Names, Information & Entities
        </Typography>
        <Box mt={3} width="45%">
          <SearchBar
            data-cy="sd-searchbar"
            hint="Search domain"
            onSearch={handleSearch}
            onEnterPress={handleEnterPress}
            validateInput={validateInput}
            openDomain={openDomain}
          />
        </Box>
        {callState === 'available' ? (
          <Box mt={4}>{renderButtons()}</Box>
        ) : callState === 'taken' ? (
          <Typography mt={4} data-cy="sd-domain-taken" color="error.main">
            Domain Taken
          </Typography>
        ) : callState === 'illegal_name' ? (
          <Typography mt={4} data-cy="sd-invalid-name" color="error.main">
            Invalid Domain name
          </Typography>
        ) : null}
      </Box>
    </Container>
  );
};

export default React.memo(SearchDomain);
