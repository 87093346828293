import { ethers } from 'ethers';
import { labelToId } from './common';
import RegistrarABI from '../contracts/abis/Registrar.json';
import RegistryABI from '../contracts/abis/Registry.json';
import ResolverABI from '../contracts/abis/Resolver.json';
import RegistrarDeployment from '../contracts/deployments/Registrar.json';
import RegistryDeployment from '../contracts/deployments/Registry.json';
import ResolverDeployment from '../contracts/deployments/Resolver.json';
import TokenABI from '../contracts/abis/Token.json';
import { getProvider, getSigner } from './wallet';
import { getState } from '../store/cns';
import { Chain } from 'src/types/Common';

// Registrar
export const nameExpires = async (label: string): Promise<string> => {
  const provider = getProvider();
  const registrar = new ethers.Contract(
    RegistrarDeployment.columbus.address,
    RegistrarABI,
    provider,
  );

  const id = labelToId(label);

  const timestamp = await registrar.nameExpires(id);
  const timestampInMilliseconds = Number(timestamp) * 1000;
  const date = new Date(timestampInMilliseconds);
  const formattedDate = date.toISOString().split('T')[0];

  return formattedDate;
};

export const available = async (label: string): Promise<boolean> => {
  const provider = getProvider();
  const registrar = new ethers.Contract(
    RegistrarDeployment.columbus.address,
    RegistrarABI,
    provider,
  );

  const id = labelToId(label);

  try {
    const available = await registrar.available(id);
    return available;
  } catch (error) {
    console.log(`Could not check domain availablitly because of: ${error}`);
    return false;
  }
};

export const register = async (
  label: string,
  numberOfYears: number,
  registrationCost: string,
): Promise<boolean> => {
  const signer = getSigner();
  const address = await signer?.getAddress();

  const selectedToken = getState().selectedToken;

  if (selectedToken) {
    const token = new ethers.Contract(
      selectedToken?.tokenAddress,
      TokenABI,
      signer,
    );
    const registrar = new ethers.Contract(
      RegistrarDeployment.columbus.address,
      RegistrarABI,
      signer,
    );
    try {
      await token.approve(
        registrar,
        ethers.parseEther(registrationCost.toString()),
      );
      await registrar.register(
        label,
        address,
        selectedToken?.tokenAddress,
        numberOfYears,
      );
      await setResolver([label, 'cam'], ResolverDeployment.columbus.address);
      return true;
    } catch (error) {
      console.log(`Domain registration failed because of: ${error}`);
      return false;
    }
  }

  console.log(`No token for payment selected.`);
  return false;
};

export const renew = async (
  label: string,
  renewalPeriod: number,
  renewalCost: string,
): Promise<boolean> => {
  const signer = getSigner();

  const selectedToken = getState().selectedToken;

  if (selectedToken) {
    const token = new ethers.Contract(
      selectedToken?.tokenAddress,
      TokenABI,
      signer,
    );
    const registrar = new ethers.Contract(
      RegistrarDeployment.columbus.address,
      RegistrarABI,
      signer,
    );
    try {
      await token.approve(registrar, ethers.parseEther(renewalCost));
      await registrar.renew(label, selectedToken?.tokenAddress, renewalPeriod);
      return true;
    } catch (error) {
      console.log(`Domain renewal failed because of: ${error}`);
      return false;
    }
  }

  console.log(`No token for payment selected.`);
  return false;
};

// Registry
export const registerSubdomain = async (
  domain: Array<string>,
  label: string,
  resolverAddress: string,
): Promise<boolean> => {
  const signer = getSigner();

  const registry = new ethers.Contract(
    RegistryDeployment.columbus.address,
    RegistryABI,
    signer,
  );
  try {
    if (resolverAddress.length != 0) {
      await registry.setSubnodeRecord(
        domain,
        label,
        signer,
        resolverAddress,
        3600,
      );
    } else {
      await registry.setSubnodeOwner(domain, label, signer);
    }
    return true;
  } catch (error) {
    console.log(`Registering subdomain failed because of: ${error}`);
    return false;
  }
};

export const setOwner = async (
  domain: Array<string>,
  newOwner: string,
): Promise<boolean> => {
  const signer = getSigner();

  const registry = new ethers.Contract(
    RegistryDeployment.columbus.address,
    RegistryABI,
    signer,
  );
  try {
    await registry.setOwner(domain, newOwner);
    return true;
  } catch (error) {
    console.log(`Settin new owner failed because of: ${error}`);
    return false;
  }
};

export const getOwner = async (domain: Array<string>): Promise<string> => {
  const provider = getProvider();

  const registry = new ethers.Contract(
    RegistryDeployment.columbus.address,
    RegistryABI,
    provider,
  );

  try {
    const owner = await registry.getOwner(domain);
    return owner;
  } catch (error) {
    console.log(`Failed to query owner because of: ${error}`);
    return '';
  }
};

export const setTTL = async (
  domain: Array<string>,
  ttl: string,
): Promise<boolean> => {
  const signer = getSigner();

  const registry = new ethers.Contract(
    RegistryDeployment.columbus.address,
    RegistryABI,
    signer,
  );
  try {
    await registry.setTTL(domain, ttl);
    return true;
  } catch (error) {
    console.log(`Set TTL failed because of: ${error}`);
    return false;
  }
};

export const getTTL = async (domain: Array<string>): Promise<number> => {
  const provider = getProvider();

  const registry = new ethers.Contract(
    RegistryDeployment.columbus.address,
    RegistryABI,
    provider,
  );

  try {
    const ttl = await registry.getTTL(domain);
    return ttl;
  } catch (error) {
    console.log(`Failed to query owner because of: ${error}`);
    return -1;
  }
};

export const getResolver = async (domain: Array<string>): Promise<string> => {
  const provider = getProvider();

  const registry = new ethers.Contract(
    RegistryDeployment.columbus.address,
    RegistryABI,
    provider,
  );

  try {
    const resolver = await registry.getResolver(domain);
    if (resolver === '0x0000000000000000000000000000000000000000') {
      return '';
    }
    return resolver;
  } catch (error) {
    console.log(`Failed to query resolver because of: ${error}`);
    return '';
  }
};

export const setResolver = async (
  domain: Array<string>,
  resolverAddress: string,
): Promise<boolean> => {
  const signer = getSigner();

  const registry = new ethers.Contract(
    RegistryDeployment.columbus.address,
    RegistryABI,
    signer,
  );

  try {
    await registry.setResolver(domain, resolverAddress);
    return true;
  } catch (error) {
    console.log(`Failed to set resolver because of: ${error}`);
    return false;
  }
};

export const setApprovalForAll = async (
  operator: string,
  approved: boolean,
): Promise<boolean> => {
  const signer = getSigner();

  const registry = new ethers.Contract(
    RegistryDeployment.columbus.address,
    RegistryABI,
    signer,
  );

  try {
    await registry.setApprovalForAll(operator, approved);
    return true;
  } catch (error) {
    console.log(`Failed to approve operator because of: ${error}`);
    return false;
  }
};

// Resolver
export const setCAddress = async (
  domain: Array<string>,
  address: string,
  resolverAddress: string,
): Promise<boolean> => {
  const signer = getSigner();

  const resolver = new ethers.Contract(resolverAddress, ResolverABI, signer);

  try {
    await resolver['setAddr(string[],address)'](domain, address);
    return true;
  } catch (error) {
    console.log(`Failed to set address because of: ${error}`);
    return false;
  }
};

export const getCAddress = async (
  domain: Array<string>,
  resolverAddress: string,
): Promise<string> => {
  const provider = getProvider();

  const resolver = new ethers.Contract(resolverAddress, ResolverABI, provider);

  try {
    const cAddress = await resolver['getAddr(string[])'](domain);
    return cAddress;
  } catch (error) {
    console.log(`Failed to query address because of: ${error}`);
    return '';
  }
};

export const setAddr = async (
  domain: Array<string>,
  address: string,
  chain: Chain,
  resolverAddress: string,
): Promise<boolean> => {
  const signer = getSigner();

  const resolver = new ethers.Contract(resolverAddress, ResolverABI, signer);

  try {
    await resolver['setAddr(string[],string,uint8)'](domain, address, chain);
    return true;
  } catch (error) {
    console.log(`Failed to set address because of: ${error}`);
    return false;
  }
};

export const getAddress = async (
  domain: Array<string>,
  resolverAddress: string,
  chain: Chain,
): Promise<string> => {
  const provider = getProvider();

  const resolver = new ethers.Contract(resolverAddress, ResolverABI, provider);

  try {
    const address = await resolver['getAddr(string[],uint8)'](domain, chain);
    return address;
  } catch (error) {
    console.log(`Failed to query address because of: ${error}`);
    return '';
  }
};

export const getPubKey = async (
  domain: Array<string>,
  resolverAddress: string,
): Promise<string> => {
  const provider = getProvider();

  const resolver = new ethers.Contract(resolverAddress, ResolverABI, provider);

  try {
    const pubKey = await resolver.getPubKey(domain);
    return pubKey;
  } catch (error) {
    console.log(`Failed to query public key because of: ${error}`);
    return '';
  }
};

export const setPubKey = async (
  domain: Array<string>,
  pubKey: string,
  resolverAddress: string,
): Promise<boolean> => {
  const signer = getSigner();

  const resolver = new ethers.Contract(resolverAddress, ResolverABI, signer);

  try {
    await resolver.setPubKey(domain, pubKey);
    return true;
  } catch (error) {
    console.log(`Failed to set public key because of: ${error}`);
    return false;
  }
};

export const getContentHash = async (
  domain: Array<string>,
  resolverAddress: string,
): Promise<string> => {
  const provider = getProvider();

  const resolver = new ethers.Contract(resolverAddress, ResolverABI, provider);

  try {
    const contentHash = await resolver.getContentHash(domain);
    return contentHash;
  } catch (error) {
    console.log(`Failed to query content hash because of: ${error}`);
    return '';
  }
};

export const setContentHash = async (
  domain: Array<string>,
  data: string,
  resolverAddress: string,
): Promise<boolean> => {
  const signer = getSigner();

  const resolver = new ethers.Contract(resolverAddress, ResolverABI, signer);

  try {
    await resolver.setContentHash(domain, data);
    return true;
  } catch (error) {
    console.log(`Failed to set content hash because of: ${error}`);
    return false;
  }
};

export const approve = async (
  domain: Array<string>,
  manager: string,
  approved: boolean,
  resolverAddress: string,
): Promise<boolean> => {
  const signer = getSigner();

  const resolver = new ethers.Contract(resolverAddress, ResolverABI, signer);

  try {
    await resolver.approve(domain, manager, approved);
    return true;
  } catch (error) {
    console.log(`Failed to approve manager because of: ${error}`);
    return false;
  }
};

// ERC-20 Token
export const mintToken = async (
  tokenAddress: string,
  amount: number,
): Promise<boolean> => {
  const signer = getSigner();
  const address = await signer?.getAddress();

  const token = new ethers.Contract(tokenAddress, TokenABI, signer);
  try {
    await token.mint(address, ethers.parseEther(amount.toString()));
    return true;
  } catch (error) {
    console.log(`Domain registration failed because of: ${error}`);
    return false;
  }
};

export const balanceOf = async (tokenAddress: string): Promise<string> => {
  const provider = getProvider();
  const signer = getSigner();
  const address = await signer?.getAddress();
  const token = new ethers.Contract(tokenAddress, TokenABI, provider);
  try {
    const balance = await token.balanceOf(address);
    return balance.toString();
  } catch (error) {
    console.log(`Domain registration failed because of: ${error}`);
    return '0';
  }
};
