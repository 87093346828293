import { ethers, namehash } from 'ethers';
import RegistrarABI from '../contracts/abis/Registrar.json';
import RegistryABI from '../contracts/abis/Registry.json';
import ResolverABI from '../contracts/abis/Resolver.json';
import RegistrarDeployment from '../contracts/deployments/Registrar.json';
import RegistryDeployment from '../contracts/deployments/Registry.json';
import ResolverDeployment from '../contracts/deployments/Resolver.json';
import { getProvider } from './wallet';
import { getTokenDetails } from './common';
import { Token, Domain, DomainTree } from 'src/types/Common';
import { available } from './contract';

export const getApprovedTokens = async (): Promise<Token[]> => {
  const provider = getProvider();
  const eventSignature = ethers.id('TokenApproved(address,uint256)');

  const logs = await provider.getLogs({
    address: RegistrarDeployment.columbus.address,
    topics: [eventSignature],
    fromBlock: RegistrarDeployment.columbus.block,
    toBlock: 'latest',
  });

  const iface = new ethers.Interface(RegistrarABI);

  const newTokens: Token[] = await Promise.all(
    logs.map(async log => {
      const decodedLog = iface.parseLog(log);

      const token = await getTokenDetails(
        decodedLog?.args[0],
        decodedLog?.args[1],
        provider,
      );
      return token;
    }),
  );

  return newTokens;
};

export const getDomainTree = async (): Promise<DomainTree | undefined> => {
  const CAM_NODE =
    '0xae699f70ed198095f47e32ff39f1f2a31565a531bc8a8f2dd472afdffe7a1a9d';

  const provider = getProvider();
  const eventSignature = ethers.id('NewOwner(bytes32,string,address)');

  const logs = await provider.getLogs({
    address: RegistryDeployment.columbus.address,
    topics: [eventSignature],
    fromBlock: RegistryDeployment.columbus.block,
    toBlock: 'latest',
  });

  const transferLogs = await provider.getLogs({
    address: RegistryDeployment.columbus.address,
    topics: [ethers.id('Transfer(bytes32,address)')],
    fromBlock: RegistryDeployment.columbus.block,
    toBlock: 'latest',
  });

  const iface = new ethers.Interface(RegistryABI);

  const argsArray: string[][] = logs
    .map(log => {
      const parsedLog = iface.parseLog(log);
      return parsedLog?.args?.map((arg: any) => arg.toString());
    })
    .filter((args): args is string[] => args !== undefined);

  const transferArray: string[][] = transferLogs
    .map(log => {
      const parsedLog = iface.parseLog(log);
      return parsedLog?.args?.map((arg: any) => arg.toString());
    })
    .filter((args): args is string[] => args !== undefined);

  const buildDomainTree = (logs: string[][]): DomainTree | undefined => {
    let domainTree: DomainTree | undefined = undefined;

    const findAndAddSubdomain = (
      node: DomainTree,
      parentHash: string,
      label: string,
      owner: string,
    ): boolean => {
      if (node.hash === parentHash) {
        const name = label + '.' + node.name;
        const hash = ethers.namehash(name);
        const subdomain = {
          name: name,
          owner: owner,
          hash: hash,
          subdomains: [],
        };
        node.subdomains.push(subdomain);
        return true;
      }
      for (const sub of node.subdomains) {
        if (findAndAddSubdomain(sub, parentHash, label, owner)) {
          return true;
        }
      }
      return false;
    };

    for (const log of logs) {
      const [parent, label, owner] = log;

      if (
        parent ===
        '0x0000000000000000000000000000000000000000000000000000000000000000'
      ) {
        domainTree = {
          name: label,
          owner,
          hash: CAM_NODE,
          subdomains: [],
        };
      } else {
        if (domainTree) {
          if (!findAndAddSubdomain(domainTree, parent, label, owner)) {
            console.warn(`Parent hash ${parent} not found for label ${label}`);
          }
        } else {
          throw new Error('Domain tree is not initialized.');
        }
      }
    }

    return domainTree;
  };

  const updateDomainOwnerByHashInTree = (
    domainTree: DomainTree,
    targetHash: string,
    newOwner: string,
  ): boolean => {
    if (domainTree.hash === targetHash) {
      domainTree.owner = newOwner;
      return true;
    }

    for (const subdomain of domainTree.subdomains) {
      if (updateDomainOwnerByHashInTree(subdomain, targetHash, newOwner)) {
        return true;
      }
    }

    return false;
  };

  if (argsArray) {
    const domainTree = buildDomainTree(argsArray);
    if (domainTree) {
      for (const index in transferArray) {
        const [targetHash, newOwner] = transferArray[index];
        updateDomainOwnerByHashInTree(domainTree, targetHash, newOwner);
      }
      return domainTree;
    }
  }
  return undefined;
};

export const getDomains = async (
  address: string,
  domainTree: DomainTree | undefined,
): Promise<Domain[]> => {
  if (!domainTree) {
    domainTree = await getDomainTree();
  }

  const filterDomainTreeByOwner = (
    node: DomainTree,
    address: string,
  ): DomainTree[] => {
    // Filter the subdomains recursively
    const filteredSubdomains = node.subdomains.flatMap(sub =>
      filterDomainTreeByOwner(sub, address),
    );

    // If the current node's owner matches the address, return it with its filtered subdomains
    if (node.owner === address) {
      return [{ ...node, subdomains: filteredSubdomains }];
    }

    // If the current node's owner does not match the address, return the filtered subdomains (i.e., promote them)
    return filteredSubdomains;
  };

  const convertDomainTreeToDomain = (
    domainTrees: DomainTree[],
    accessRights: string,
  ): Domain[] => {
    return domainTrees.map(domainTree => {
      return {
        name: domainTree.name,
        accessRights: accessRights,
        subdomains: convertDomainTreeToDomain(
          domainTree.subdomains,
          accessRights,
        ),
      };
    });
  };

  const findDomainsByHashes = (
    domainTree: DomainTree,
    hashes: string[],
  ): Domain[] => {
    const result: Domain[] = [];

    const searchDomainByHash = (
      node: DomainTree,
      targetHash: string,
    ): Domain | undefined => {
      if (node.hash === targetHash) {
        return {
          name: node.name,
          accessRights: 'manager',
          subdomains: [],
        };
      }

      for (const subdomain of node.subdomains) {
        const foundDomain = searchDomainByHash(subdomain, targetHash);
        if (foundDomain) {
          return foundDomain;
        }
      }

      return undefined;
    };

    for (const hash of hashes) {
      const foundDomain = searchDomainByHash(domainTree, hash);
      if (foundDomain) {
        result.push(foundDomain);
      }
    }

    return result;
  };

  if (domainTree) {
    const filteredDomainTree = filterDomainTreeByOwner(domainTree, address);
    if (filteredDomainTree) {
      // Domains the user owns
      let domains = convertDomainTreeToDomain(filteredDomainTree, 'owner');
      for (const i in domains) {
        const index = Number(i);
        const domainArr = domains[index].name.split('.');
        const domainInactive = await available(domainArr[domainArr.length - 2]);
        if (domainInactive) {
          domains.splice(index, 1);
        }
      }
      // Domains the user has access rights to
      const approvedForOperator = await getOwnersByOperator(address);
      for (const i in approvedForOperator) {
        const tree = filterDomainTreeByOwner(
          domainTree,
          approvedForOperator[i],
        );
        const operatorDomains = convertDomainTreeToDomain(tree, 'operator');
        domains = domains.concat(operatorDomains);
      }
      // Domains the user has Manager rights in the resolver
      const managedDomainHashes = await getDomainsByManager(
        address,
        ResolverDeployment.columbus.address,
      );
      const managerDomains = findDomainsByHashes(
        domainTree,
        managedDomainHashes,
      );
      domains = domains.concat(managerDomains);
      return domains;
    }
  }

  return [];
};

export const getManagers = async (
  domain: string,
  resolverAddress: string,
): Promise<string[]> => {
  const provider = getProvider();
  const eventSignature = ethers.id('OperatorApproved(bytes32,address,bool)');

  const logs = await provider.getLogs({
    address: resolverAddress,
    topics: [eventSignature],
    fromBlock: RegistryDeployment.columbus.block,
    toBlock: 'latest',
  });

  const iface = new ethers.Interface(ResolverABI);

  const node = namehash(domain);

  const managers: string[] = [];

  logs.map(log => {
    const decodedLog = iface.parseLog(log);
    const args = decodedLog?.args;
    if (args && args[0].includes(node)) {
      if (args[2]) {
        if (!managers.includes(args[1])) {
          managers.push(args[1]);
        }
      } else {
        const index = managers.indexOf(args[1]);
        if (index > -1) {
          managers.splice(index, 1);
        }
      }
    }
  });

  return managers;
};

export const getDomainsByManager = async (
  managerAddress: string,
  resolverAddress: string,
): Promise<string[]> => {
  const provider = getProvider();
  const eventSignature = ethers.id('OperatorApproved(bytes32,address,bool)');

  const logs = await provider.getLogs({
    address: resolverAddress,
    topics: [eventSignature, null, ethers.zeroPadValue(managerAddress, 32)],
    fromBlock: RegistryDeployment.columbus.block,
    toBlock: 'latest',
  });

  const iface = new ethers.Interface(ResolverABI);

  const domains: string[] = [];

  logs.map(log => {
    const decodedLog = iface.parseLog(log);
    const args = decodedLog?.args;
    if (args && args[1].toLowerCase() === managerAddress.toLowerCase()) {
      if (args[2]) {
        if (!domains.includes(args[0])) {
          domains.push(args[0]);
        }
      } else {
        const index = domains.indexOf(args[0]);
        if (index > -1) {
          domains.splice(index, 1);
        }
      }
    }
  });

  return domains;
};

export const getOwnersByOperator = async (
  operator: string,
): Promise<string[]> => {
  const provider = getProvider();
  const eventSignature = ethers.id('ApprovalForAll(address,address,bool)');

  const logs = await provider.getLogs({
    address: RegistryDeployment.columbus.address,
    topics: [eventSignature, null, ethers.zeroPadValue(operator, 32)],
    fromBlock: RegistryDeployment.columbus.block,
    toBlock: 'latest',
  });

  const iface = new ethers.Interface(RegistryABI);

  const owners: string[] = [];

  logs.map(log => {
    const decodedLog = iface.parseLog(log);
    const args = decodedLog?.args;
    if (args && args[2]) {
      // approved
      if (!owners.includes(args[0])) {
        owners.push(args[0]);
      }
    } else {
      if (args) {
        const index = owners.indexOf(args[0]);
        if (index > -1) {
          owners.splice(index, 1);
        }
      }
    }
  });

  return owners;
};

export const getOperatorsByOwner = async (owner: string): Promise<string[]> => {
  const provider = getProvider();
  const eventSignature = ethers.keccak256(
    ethers.toUtf8Bytes('ApprovalForAll(address,address,bool)'),
  );

  const logs = await provider.getLogs({
    address: RegistryDeployment.columbus.address,
    topics: [eventSignature, ethers.zeroPadValue(owner, 32), null],
    fromBlock: RegistryDeployment.columbus.block,
    toBlock: 'latest',
  });

  const iface = new ethers.Interface(RegistryABI);

  const operators: string[] = [];

  logs.forEach(log => {
    const decodedLog = iface.decodeEventLog(
      'ApprovalForAll',
      log.data,
      log.topics,
    );
    const { operator, approved } = decodedLog;

    if (approved) {
      if (!operators.includes(operator)) {
        operators.push(operator);
      }
    } else {
      const index = operators.indexOf(operator);
      if (index > -1) {
        operators.splice(index, 1);
      }
    }
  });

  return operators;
};
