import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  FormControl,
  FormLabel,
} from '@mui/material';
import {
  setAddr,
  setCAddress,
  setContentHash,
  setPubKey,
  setResolver,
} from 'src/utils/contract';
import { Chain } from 'src/types/Common';
import Loading from '../widget/Loading';
import useStore from 'src/store/cns';

interface Props {
  domain: string;
}

const ResolverSettings: React.FC<Props> = ({ domain }) => {
  const [resolverInput, setResolverInput] = useState<string>('');
  const [cAddressInput, setCAddressInput] = useState<string>('');
  const [pAddressInput, setPAddressInput] = useState<string>('');
  const [xAddressInput, setXAddressInput] = useState<string>('');
  const [pubKeyInput, setPubKeyInput] = useState<string>('');
  const [contentHashInput, setContentHashInput] = useState<string>('');

  const setSnackbar = useStore(state => state.setSnackbar);
  const selectedDomain = useStore(state => state.selectedDomain);
  const callState = useStore(state => state.callState);
  const setCallState = useStore(state => state.setCallState);
  const loadingText = useStore(state => state.loadingText);
  const setLoadingText = useStore(state => state.setLoadingText);

  const inputs = selectedDomain?.resolver
    ? [
        {
          label: 'Resolver Address',
          prop: selectedDomain?.resolver?.resolverAddress,
          inputValue: resolverInput,
          function: setResolverInput,
          onClick: setResolver,
          args: [domain.split('.'), resolverInput],
        },
        {
          label: 'C-Chain Address',
          prop: selectedDomain?.resolver?.cChainAddress,
          inputValue: cAddressInput,
          function: setCAddressInput,
          onClick: setCAddress,
          args: [
            domain.split('.'),
            cAddressInput,
            selectedDomain?.resolver?.resolverAddress,
          ],
        },
        {
          label: 'X-Chain Address',
          prop: selectedDomain?.resolver?.pChainAddress,
          inputValue: pAddressInput,
          function: setPAddressInput,
          onClick: setAddr,
          args: [
            domain.split('.'),
            xAddressInput,
            Chain.X,
            selectedDomain?.resolver?.resolverAddress,
          ],
        },
        {
          label: 'P-Chain Address',
          prop: selectedDomain?.resolver?.xChainAddress,
          inputValue: xAddressInput,
          function: setXAddressInput,
          onClick: setAddr,
          args: [
            domain.split('.'),
            pAddressInput,
            Chain.P,
            selectedDomain?.resolver?.resolverAddress,
          ],
        },
        {
          label: 'Public Key',
          prop: selectedDomain?.resolver?.publicKey,
          inputValue: pubKeyInput,
          function: setPubKeyInput,
          onClick: setPubKey,
          args: [
            domain.split('.'),
            pubKeyInput,
            selectedDomain?.resolver?.resolverAddress,
          ],
        },
        {
          label: 'Content Hash',
          prop: selectedDomain?.resolver?.contentHash,
          inputValue: contentHashInput,
          function: setContentHashInput,
          onClick: setContentHash,
          args: [
            domain.split('.'),
            contentHashInput,
            selectedDomain?.resolver?.resolverAddress,
          ],
        },
      ]
    : [
        {
          label: 'Resolver Address',
          prop: '',
          inputValue: resolverInput,
          function: setResolverInput,
          onClick: setResolver,
          args: [domain.split('.'), resolverInput],
        },
        {
          label: 'C-Chain Address',
          prop: '',
          inputValue: cAddressInput,
          function: setCAddressInput,
          onClick: setCAddress,
          args: [
            domain.split('.'),
            cAddressInput,
            selectedDomain?.resolver?.resolverAddress,
          ],
        },
        {
          label: 'X-Chain Address',
          prop: '',
          inputValue: pAddressInput,
          function: setPAddressInput,
          onClick: setAddr,
          args: [
            domain.split('.'),
            xAddressInput,
            Chain.X,
            selectedDomain?.resolver?.resolverAddress,
          ],
        },
        {
          label: 'P-Chain Address',
          prop: '',
          inputValue: xAddressInput,
          function: setXAddressInput,
          onClick: setAddr,
          args: [
            domain.split('.'),
            pAddressInput,
            Chain.P,
            selectedDomain?.resolver?.resolverAddress,
          ],
        },
        {
          label: 'Public Key',
          prop: '',
          inputValue: pubKeyInput,
          function: setPubKeyInput,
          onClick: setPubKey,
          args: [
            domain.split('.'),
            pubKeyInput,
            selectedDomain?.resolver?.resolverAddress,
          ],
        },
        {
          label: 'Content Hash',
          prop: '',
          inputValue: contentHashInput,
          function: setContentHashInput,
          onClick: setContentHash,
          args: [
            domain.split('.'),
            contentHashInput,
            selectedDomain?.resolver?.resolverAddress,
          ],
        },
      ];

  const handleUpdate = async (
    object: string,
    funcName: (...args: any[]) => Promise<boolean>,
    args: any[],
  ) => {
    setLoadingText(`Updating ${object}...`);
    setCallState('loading');
    if (await funcName(...args)) {
      setSnackbar({
        open: true,
        message: `Successfully updated ${object}.`,
        severity: 'success',
      });
    } else {
      setSnackbar({
        open: true,
        message: `Failed to update ${object}.`,
        severity: 'error',
      });
    }
    setCallState('idle');
  };

  useEffect(() => {
    if (selectedDomain?.resolver) {
      setResolverInput(selectedDomain?.resolver?.resolverAddress);
      setCAddressInput(selectedDomain?.resolver?.cChainAddress);
      setPAddressInput(selectedDomain?.resolver?.pChainAddress);
      setXAddressInput(selectedDomain?.resolver?.xChainAddress);
      setPubKeyInput(selectedDomain?.resolver?.publicKey);
      setContentHashInput(selectedDomain?.resolver?.contentHash);
    }
  }, [selectedDomain]);

  return (
    <Container maxWidth="sm">
      {callState === 'loading' ? (
        <Loading text={loadingText} />
      ) : (
        <Box mt={4}>
          {inputs.map(input => (
            <Grid
              key={input.label}
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              direction="row"
              mb={5}
            >
              <Grid item xs={8}>
                <FormControl fullWidth>
                  <FormLabel
                    sx={{
                      position: 'absolute',
                      transform: 'translateY(-24px)',
                      pointerEvents: 'none',
                    }}
                  >
                    {input.label}
                  </FormLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={input.inputValue}
                    placeholder={`Set ${input.label}`}
                    disabled={
                      input.label !== 'Resolver Address' &&
                      !selectedDomain?.resolver
                    }
                    onChange={e => input.function(e.target.value)}
                    InputLabelProps={{
                      shrink: false,
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        height: '48px',
                        '&:hover fieldset': {
                          borderColor: 'grey.600',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'grey.600',
                        },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    color: 'text.primary',
                    backgroundColor: 'primary.main',
                    padding: '10px 16px',
                  }}
                  onClick={() =>
                    handleUpdate(input.label, input.onClick, input.args)
                  }
                >
                  {input.prop.length > 0 ? (
                    <Typography>Update</Typography>
                  ) : (
                    <Typography>Set</Typography>
                  )}
                </Button>
              </Grid>
            </Grid>
          ))}
        </Box>
      )}
    </Container>
  );
};

export default ResolverSettings;
