import React, { useState } from 'react';
import {
  InputAdornment,
  Container,
  Grid,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import useStore from 'src/store/cns';
import { renew } from 'src/utils/contract';
import { Token, Domain } from 'src/types/Common';
import Loading from '../widget/Loading';
import TokenSelection from '../widget/TokenSelection';
import TimePeriod from '../widget/TimePeriod';

interface Props {
  onValueChange: (expirationDate: string, renewalPeriod: number) => void;
  domain: Domain;
  expirationDate: string;
}

const DomainExpiration: React.FC<Props> = ({
  onValueChange,
  domain,
  expirationDate,
}) => {
  const callState = useStore(state => state.callState);
  const setCallState = useStore(state => state.setCallState);
  const loadingText = useStore(state => state.loadingText);
  const setLoadingText = useStore(state => state.setLoadingText);
  const selectedToken = useStore(state => state.selectedToken);
  const isLoggedIn = useStore(state => state.isLoggedIn);

  const [renewalCost, setRenewalCost] = useState(
    Number(selectedToken?.basePrice),
  );
  const [renewalPeriod, setRenewalPeriod] = useState(1);

  const setSnackbar = useStore(state => state.setSnackbar);

  const handleChange = (token: Token) => {
    if (token?.basePrice) {
      setRenewalCost(token?.basePrice * renewalPeriod);
    }
  };

  const handleIncrementYear = () => {
    setRenewalPeriod(prevPeriod => prevPeriod + 1);
    if (selectedToken?.basePrice) {
      setRenewalCost(prevCost => prevCost + selectedToken.basePrice);
    }
  };

  const handleDecrementYear = () => {
    if (renewalPeriod > 1) {
      setRenewalPeriod(prevPeriod => prevPeriod - 1);
      if (selectedToken?.basePrice) {
        setRenewalCost(prevCost => prevCost - selectedToken.basePrice);
      }
    }
  };

  const renewDomain = async () => {
    if (selectedToken) {
      setLoadingText('Renewing domain...');
      setCallState('loading');
      const label = domain.name.split('.')[domain.name.split('.').length - 2];
      if (await renew(label, renewalPeriod, renewalCost.toString())) {
        setSnackbar({
          open: true,
          message: `Successfully renewed domain.`,
          severity: 'success',
        });
      } else {
        setSnackbar({
          open: true,
          message: `Failed to renew domain.`,
          severity: 'error',
        });
      }
      setCallState('idle');
      onValueChange(expirationDate, renewalPeriod);
    }
  };

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2} rowSpacing={4} alignItems="center">
        <Grid item xs={6} display="flex" justifyContent="flex-start">
          <TextField
            data-cy="expiration-date"
            fullWidth
            label="Expiration Date"
            variant="outlined"
            value={expirationDate}
            sx={{
              '& .MuiOutlinedInput-root': {
                height: '46px',
                '&:hover fieldset': {
                  borderColor: 'grey.600',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'grey.600',
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarTodayIcon sx={{ color: '#9c9c9c' }} />
                </InputAdornment>
              ),
              sx: { color: '#fff' },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled
          />
        </Grid>

        {domain.name.split('.').length === 2 && (
          <>
            <Grid item xs={6} display="flex" justifyContent="center">
              <TimePeriod
                timePeriod={renewalPeriod}
                handleIncrementYear={handleIncrementYear}
                handleDecrementYear={handleDecrementYear}
              />
            </Grid>

            {callState === 'loading' ? (
              <Grid item xs={12} display="flex" justifyContent="center" mt={2}>
                <Loading text={loadingText} />
              </Grid>
            ) : (
              <>
                <Grid item xs={6} display="flex" justifyContent="center">
                  <TokenSelection onValueChange={handleChange} />
                </Grid>
                <Grid item xs={6} display="flex" justifyContent="center">
                  {isLoggedIn && selectedToken ? (
                    <Button
                      variant="contained"
                      onClick={renewDomain}
                      sx={{
                        color: 'text.primary',
                        backgroundColor: 'primary.main',
                        padding: '10px 16px',
                      }}
                    >
                      Renew for {renewalCost} {selectedToken?.symbol}
                    </Button>
                  ) : (
                    <Typography>Could not detect wallet or token</Typography>
                  )}
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Container>
  );
};

export default DomainExpiration;
